body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
    background-image: url(/static/media/background.b3cdacff.jpg);
    background-color: teal;
    min-height: 500px; /* You must set a specified height */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
}

.valign-center {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
}

.strikethrough {
    text-decoration: line-through;
}

.card {
    background-color: white;
}

.card h1 {
    background: linear-gradient(rgba(255,0,0,0) 50%, teal 50%);
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 70vh;
}

.card {
    padding: 2% 8%;
    /* border: 1px solid black;
    box-shadow: 5px 5px 10px black; */
}
